export const msalConfig = {
  auth: {
    clientId: 'ab8e0739-31a2-42ae-9a55-b5acdcbe80f6', // This is the ONLY mandatory field that you need to supply.
    authority: 'https://login.microsoftonline.com/interactuarb2ctest.onmicrosoft.com', // Choose SUSI as your default authority.
    knownAuthorities:  ['interactuarb2ctest.b2clogin.com'], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  }
}