import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { GlobalProvider } from "./globalProvider"; // Importa tu GlobalProvider
import { msalConfig } from "../config/msalConfig";


const pca = new PublicClientApplication(msalConfig);

const CombinedProvider = ({ children }) => {
  return (
    <MsalProvider instance={pca}>
      <GlobalProvider>{children}</GlobalProvider>
    </MsalProvider>
  );
};

export default CombinedProvider;
