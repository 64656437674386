import React, { createContext, useState } from "react";

// Crear el contexto
export const GlobalContext = createContext();

// Crear el proveedor de contexto
export const GlobalProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [results, setResults] = useState();
  const [tableIsLoading, setTableisLoading] = useState(false);
  const [menuSelected, setMenuSelected] = useState("sub0");
  const [detailInfo, setDetailInfo] = useState({});

  const availableValues = {
    results,
    setResults,
    tableIsLoading,
    setTableisLoading,
    userInfo,
    setUserInfo,
    isAuthenticated,
    setIsAuthenticated,
    menuSelected,
    setMenuSelected,
    detailInfo,
    setDetailInfo,
  }

  return (
    <GlobalContext.Provider value={availableValues}>
      {children}
    </GlobalContext.Provider>
  );
};
