import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createHashHistory } from "history";
import PrivateRoute from "./privateRoute";


const history = createHashHistory();

const routes = [
  {
    path: "/",
    component: lazy(() => import("../pages/home")),
    exact: true,
    isPrivate: false,
  },
];

const RouterRoot = () => {
  const isAuthenticated = true;
  return (
    <BrowserRouter history={history}>
      <Switch>
        {routes.map(({ path, component: Component, exact, isPrivate }) =>
          isPrivate ? (
            <Route
              path={path}
              exact={exact}
              render={() => (
                <Suspense>
                  <Component />
                </Suspense>
              )}
            />
          ) : (
            <PrivateRoute
              path={path}
              component={Component}
              isAuthenticated={isAuthenticated}
              exact={exact}
            />
          )
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default RouterRoot;
