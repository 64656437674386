import React from 'react';
import RouterRoot from './routes/routes'
import CombinedProvider from './providers/combinedProviders';

function App() {
  return (
    <CombinedProvider>
      <RouterRoot />
    </CombinedProvider>
  );
}

export default App;
